<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <span class="bread-tx">当前位置：</span>
        <el-breadcrumb-item v-for="(item, index) in breadList" :key="index">
          <a v-if="item.link"  :href="'/#' + item.link" @click="toPage(item)">{{ item.name }}</a>
          <a v-else href="javascript:void(0)">{{ item.name }}</a>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import { breadcrumb, breadcrumbItem } from "element-ui";
export default {
  inject: ["routerRefresh"], //在子组件中注入在父组件中创建的属性
  components: {
    "el-breadcrumb": breadcrumb,
    "el-breadcrumb-item": breadcrumbItem,
  },
  props: {
    content: String,
    breadList: Array,
  },
  created() {
    console.log(" --- ", this.breadList);
  },
  data() {
    return {
      // breadList: [],
    };
  },
  methods: {
    toPage(item) {
      console.log("item --- ", item)
      this.routerRefresh();
      //
    }
  }
};
</script>
<style lang="scss">
.el-breadcrumb {
  font-size: 12px;
}
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  color: #888888;
  font-weight: inherit;
}
.el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
  color: #c01515;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.crumbs {
  font-size: 12px;
  color: #888888;
  line-height: 17px;
  border-bottom: 1px solid #c01515;
  padding: 10px;
  .bread-tx {
    float: left;
  }
}
</style>
